














import VerticalTabs from '@/components/VerticalTabs.vue'
import { Component, ProvideReactive, Vue } from 'vue-property-decorator'
import Loading from '@/elements/Loading.vue'
import Modal from '@/components/Modal.vue'
import { namespace } from 'vuex-class'

import { League } from '@/GeneratedTypes/League'
import { namespace as leagueStoreName } from '@/store/leagues'
import { Route } from 'vue-router'
import { CategoryNameWithoutProgram } from '@/views/Programs/Volunteers/ext/roleutils'
import { getRouteCategoryName, getLeagueType } from '@/views/Programs/Volunteers/ext/routeutils'
import { RuntimeException } from '@/lib/common/exceptions/RuntimeException'
import programsClient from '@/clients/programsClient'
import store from '@/store'

const league = namespace(leagueStoreName)

class VolunteerIndexRuntimeException extends RuntimeException {
  name = 'Volunteer Index Runtime Error'
}

@Component({
  components: {
    VerticalTabs,
    Loading,
    Modal,
  },
  // https://github.com/kaorun343/vue-property-decorator/issues/277#issuecomment-558594655
  inject: [],
})
export default class VolunteerIndex extends Vue {
  @ProvideReactive()
  private isHistoricalProgram = false

  private get id() {
    return this.$route.params.id
  }
  private get routeName() {
    return this.$route.name
  }

  private get routeId() {
    return this.id ? `${this.id}/` : ''
  }

  private get baseRoute() {
    return `/programs/${this.routeType}/${this.routeId}volunteers`
  }

  private resolveCoaches(route: Route, to: string) {
    return (
      CategoryNameWithoutProgram(getRouteCategoryName(route.path)) ==
      CategoryNameWithoutProgram(getRouteCategoryName(to))
    )
  }

  private get routeType(): string {
    return getLeagueType(this.$route.fullPath)
  }

  private get tabs() {
    let tabs: { text: string; to: string }[] = []
    if (
      this.routeName?.startsWith('list-volunteer') ||
      this.routeName?.startsWith('former-volunteer') ||
      this.routeName?.startsWith('search-volunteer') ||
      this.routeName?.startsWith('delete-volunteer')
    ) {
      tabs = [{ text: 'Coaches', to: `${this.baseRoute}/list/coaches` }]

      if (this.routeType == 'league') {
        if (!store.getters.leagueAbstraction.isUpwardSelect) {
          tabs.push({
            text: `${store.getters.leagueAbstraction.refLabel}s`,
            to: `${this.baseRoute}/list/referees`,
          })
        }
        if (this.league && this.league.programs && this.league.programs.length > 1) {
          tabs.splice(0, 0, {
            text: 'Cheer Director',
            to: `${this.baseRoute}/list/cheerdirector-${this.league.programs[1].typeProgramID.toLowerCase()}`,
          })
        }

        tabs.push({ text: 'Commissioners', to: `${this.baseRoute}/list/commissioners` })
        tabs.push({ text: 'Other Volunteers', to: `${this.baseRoute}/list/others` })
      }
    }
    return tabs
  }

  /** getters */
  @league.Getter('currentItem') league!: League

  async created() {
    if (!this.routeId) {
      throw new VolunteerIndexRuntimeException("Can't enter a volunteer Editor without a League selected")
    }

    try {
      const restResult = await programsClient.retrieveAll(true)
      const leagueInfo = restResult.data?.find((p) => p.upwardLeagueID === this.id)
      this.isHistoricalProgram = leagueInfo?.isHistorical || false
    } catch (e) {
      console.error('There was a problem fetching the program', this.id)
    }
  }
}
